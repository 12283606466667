:root {
    --primary: #008080;
    --border: #842029;
    --secondary: #fed716;
    --tertiary: #8b8b8b;
    --umd: #842029;
    --shadow: 5px 5px 25px 0 rgb(46 61 73 / 20%);
    --shadow-1: 5px 5px 15px 0 rgb(46 61 73 / 20%);
    --brush: 'Nanum Brush Script', cursive;
}

/* body {
    
} */

.p_head {
    font-size: 1.145rem;
    line-height: "32px";
    color: "#2e3d49";
    font-weight: 400;
    font-family: Open Sans,sans-serif;
}

.s_head {
    font-size: 1.125rem;
    line-height: "32px";
    color: "#2e3d49";
    font-weight: 300;
    font-family: Open Sans,sans-serif;
}

.primary-bg {
    background-color: var(--primary);
    color: #fff;
}

.secondary-bg {
    background-color: var(--border);
    color: #fff;
}

.active {
    font-style: italic;
    color: var(--primary) !important;
    font-weight: 700 !important;
    transition: 0.5s ease;
}

a {
    text-decoration: none;
    color: #000;
}

a:hover {
    color: #000;
}

.small-text {
    font-size: 0.875rem;
}

.badge-color {
    background-color: rgb(0 128 128 / 30%);
    color: #000;
    font-weight: 300;
}

.badge-imp-color {
    background-color: rgb(255 193 7 / 30%);
    color: #000;
    font-weight: 300;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-hidden {
    overflow: hidden;
}

.h50 {
    height: 50vh;
}

.h60 {
    height: 60vh;
}

.f300 {
    font-weight: 300;
}

.w-60 {
    width: 60vw;
}

mark {
    background-color: var(--primary);
    color: white;
    letter-spacing: .125rem;
    transition: all .3s ease;
    text-transform: uppercase;
}

.wmde-markdown > blockquote, .wmde-markdown > blockquote blockquote {
    border-left: 0.25em solid var(--primary);
    color:#000;
}

.wmde-markdown {
    -webkit-text-size-adjust: 100%;
    font-family: -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
    font-size: 16px;
    line-height: 1.5;
    word-wrap: break-word;
    background-color: #fff;
    color: #000;

}


.react-time-picker__button svg {
    display: none;
}

.react-time-picker__button:enabled {
    display: none;
}


.marquee {
    margin: 0 auto;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .marquee span {
    display: inline-block;
    width: max-content;
    padding-left: 100%;
    /*showthemarqueejustoutsidetheparagraph*/will-change: transform;
    animation: marquee 30s linear infinite;
    -webkit-animation: marquee 30s linear infinite;
}
  
  .marquee span:hover {
    animation-play-state: paused
  }
  
  
  
  @keyframes marquee {
    0% { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
  }
  
  
  /* Respect user preferences about animations */
  
  @media (prefers-reduced-motion: reduce) {
    .marquee span {
      animation-iteration-count: 1;
      animation-duration: 0.01; 
      /* instead of animation: none, so an animationend event is 
       * still available, if previously attached.
       */
      width: auto;
      padding-left: 0;
    }
  }

@media(max-width: 568px) {
    .w-md-editor-toolbar ul, .w-md-editor-toolbar li {
        margin: -2px !important;
    }
}

@media (min-width: 767px) {
    .life-philosophy {
        width: 45vw;
    }
    
}

/* Media query for extra-large screens, adjust the min-width as needed */
@media screen and (min-width: 1600px) { /* 1200px is a common breakpoint for extra-large screens */
    body {
      /* Scale up everything within the body by 125% */
      transform: scale(1.25);
      transform-origin: top left; /* Keeps the origin at the top left corner */
      width: 80%; /* Adjust width to compensate for the increased size due to scaling */
      /* You might need to adjust the height as well depending on your layout */
    }
  }
  