.app-container {
  display: flex;
  flex-direction: column;
  // min-height: 100vh;
}

.navbar-container {
  color: var(--primary);
  cursor: pointer;
  font-size: clamp(1rem, 2.5vw, 1.25rem);
  margin-top: 1rem;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

.min-vh-100 {
  min-height: 100vh;
}

.flex-grow-1 {
  flex-grow: 1;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  // max-height: 100vh;
  // overflow: hidden;
}