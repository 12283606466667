
#tags {
    flex-wrap: wrap;
}
.tag {
    list-style: none;
}
.tag .tag-close-icon {
    cursor: pointer;
}
